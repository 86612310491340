import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { fetchOrder } from "../../redux/slices/order";

// Регистрируем компоненты для Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ProfitChart = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [monthlyProfits, setMonthlyProfits] = useState(Array(12).fill(0));

  const dispatch = useDispatch();
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchOrder())
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        setError("Не удалось загрузить заказы. Попробуйте еще раз.");
      });
  }, [dispatch]);

  useEffect(() => {
    if (order?.items) {
      const profits = Array(12).fill(0); // Инициализируем массив для прибыли по месяцам

      order.items
        .filter(item => item.state === true) // Учитываем только заказы с state: true
        .forEach(item => {
          const date = new Date(item.date);
          const month = date.getMonth(); // Получаем номер месяца (0 - январь, 11 - декабрь)
          profits[month] += item.price; // Суммируем прибыль за каждый месяц
        });

      setMonthlyProfits(profits);
    }
  }, [order]);

  if (isLoading) return <div>Загрузка...</div>;
  if (error) return <div>{error}</div>;

  const data = {
    labels: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    datasets: [
      {
        label: "Прибыль",
        data: monthlyProfits,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const options = {
    plugins: {
      title: {
        text: "Прибыль по месяцам",
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Месяцы",
        },
      },
      y: {
        title: {
          display: true,
          text: "Прибыль (₽)",
        },
      },
    },
  };

  return (
    <div style={{ marginBottom: "50px", height: "340px" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProfitChart;
