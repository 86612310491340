import React from "react";
import { useForm } from "react-hook-form";

import "./OrderCard.scss";

export const OrderCard = ({
  name,
  phone,
  email,
  orderNumber,
  price,
  group,
  type,
  bathroom_count,
  payment_type,
  comment,
  address,
  intercom,
  room_count,
  date,
  additional_services,
  item,
  editingItemId,
  onEditButtonClick,
  onCancelEdit,
  onSave,
  orderState,

  setOrderState,
}) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      group: item.group,
      type: item.type,
      bathroom_count: item.bathroom_count,
      additional_services: item.additional_services,
      address: item.address,
      intercom: item.intercom,
      payment_type: item.payment_type,
      comment: item.comment,
      state: item.state ? "Готов заказ" : "Не Готов",
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    const state = orderState === "Готов заказ";
    onSave(item._id, state);
  };

  const handleSave = () => {
    document.location.href = "/order";
  };

  return (
    <div className="order-card">
      <div className="order-card__section order-card__section--user">
        <h2 className="order-card__title">Заказ № {orderNumber}</h2>
        <p className="order-card__user-name">
          {name}
          {/* <span className="order-card__user-role">— Пользователь</span> */}
        </p>
        <p className="order-card__user-contact">{phone}</p>
        <p className="order-card__user-email">{email}</p>
        {/* <p className="order-card__date">от 01.01.2025</p> */}
        <p className="order-card__fix-order">Заказ закрепить</p>
        <div className="order-card__cleaner-selector">
          <label className="order-card__cleaner">
            <input type="radio" name="cleaner" value="001" />
            клинер №001
          </label>
          <label className="order-card__cleaner">
            <input type="radio" name="cleaner" value="002" />
            клинер №002
          </label>
          <button className="order-card__save-button">Сохранить</button>
        </div>
      </div>

      <div className="order-card__section order-card__section--address">
        <h3 className="order-card__subtitle">Адрес Заказа</h3>
        <p>
          <strong>Дата уборки:</strong> {date}
        </p>
        <p>Город: {address.city}</p>
        <p>Улица: {address.street}</p>
        <p>Дом: {address.house}</p>
        <p>Квартира: {address.apartment}</p>
        <p>Домофон: {intercom}</p>
        <p>
          Пожелания по заказу - <br /> {comment}
        </p>
      </div>

      <div className="order-card__section order-card__section--details">
        <h3 className="order-card__subtitle">Информация о заказе</h3>
        <p>Формат уборки - {type}</p>
        <p>Объект - {group}</p>
        <p>Комнаты - {room_count}</p>
        <p>Санузел - {bathroom_count}</p>
        <p>
          Дополнительные услуги:
          <ul className="order-card__services">
            {Array.isArray(additional_services) &&
              additional_services.map(service => (
                <li key={service.id}>
                  <div>
                    {service.name} - [{service.count}] - ({service.price}₽)
                  </div>
                </li>
              ))}
          </ul>
        </p>
      </div>

      <div className="order-card__section order-card__section--cost">
        <h3 className="order-card__subtitle">Стоимость</h3>
        <p className="order-card__price">{price} руб</p>
        <p>
          <strong>Клинер:</strong>{" "}
          <span className="order-card__discount">-15%</span>
        </p>
        <p>
          <strong>Оплата:</strong> {payment_type}
        </p>
        <p>
          <strong>Статус Заказа:</strong>
        </p>
        {/* <div className="order-card__status">
          <label>
            <input type="radio" name="status" value="ready" />
            Готов заказ
          </label>
          <label>
            <input type="radio" name="status" value="cancel" />
            Отменить заказ
          </label>
        </div> */}
        {/* <div className="order-card__actions">
          <button className="order-card__save-button">Редактировать</button>
          <button className="order-card__save-button">Удалить</button>
        </div> */}
        {editingItemId === item._id ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="client">
              <div className="client__container">
                <label>
                  <div className="client__container-input">
                    <div>Заказ готов</div>
                    <input
                      type="radio"
                      name={`state-${item._id}`}
                      value="Готов заказ"
                      checked={orderState === "Готов заказ"}
                      onChange={e => setOrderState(e.target.value)}
                    />
                  </div>
                  <div className="client__container-input">
                    <div>Не Готов</div>
                    <input
                      type="radio"
                      name={`state-${item._id}`}
                      value="Не Готов"
                      checked={orderState === "Не Готов"}
                      onChange={e => setOrderState(e.target.value)}
                    />
                  </div>
                </label>
              </div>
              <div className="client__container">
                <button type="submit" onClick={onSave} className="button">
                  Сохранить
                </button>
                <button type="button" onClick={onCancelEdit} className="button">
                  Отменить
                </button>
              </div>
            </div>
          </form>
        ) : (
          <button onClick={() => onEditButtonClick(item._id)}>
            Редактировать
          </button>
        )}
      </div>
    </div>
  );
};
