import MyCalendar from "../../widgets/MyCalendar/MyCalendar";

const Calendar = () => {
  return (
    <div>
      <MyCalendar />
    </div>
  );
};
export default Calendar;
