import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "../../redux/slices/order";
import "./PaymentTable.scss";

const PaymentTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchOrder())
      .then(() => {
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        setError("Не удалось загрузить заказы. Попробуйте еще раз.");
      });
  }, [dispatch]);

  if (isLoading) return <div>Загрузка...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="payment-table">
      <table>
        <thead>
          <tr>
            <th>Дата Платежа</th>
            <th>Номер заказа</th>
            <th>Сумма заказа</th>
            <th>Клинер</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {order.items &&
            order.items.map((item, index) => (
              <tr key={index}>
                <td>{new Date(item.date).toLocaleDateString()}</td>
                <td>{item.orderNumber}</td>
                <td>{item.price} ₽</td>
                <td>-</td>
                <td>
                  <span
                    className={`payment-table__status payment-table__status--${
                      item.state ? "paid" : "complete"
                    }`}>
                    {item.state ? "Оплачен" : "Не оплачен"}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentTable;
