import React from "react";
import { useSelector } from "react-redux";
import "./InfoProfitBlock.scss";

const InfoProfitBlock = ({}) => {
  const filteredOrders = useSelector(state => state.calendar.filteredOrders);
  // Суммируем доход
  const profit = filteredOrders.reduce((sum, order) => sum + order.price, 0);
  return (
    <div>
      <div className="info__profit">
        <div className="info__profit-block">
          <div className="info__profit-title">Доход</div>
          <div className="info__profit-value">
            {profit.toLocaleString("ru-RU")} ₽
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoProfitBlock;
