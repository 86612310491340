import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth";
import { orderReducer } from "./slices/order";
import { requestReducer } from "./slices/request";
import { userReducer } from "./slices/user";
import { roleReducer } from "./slices/role";
import { workReducer } from "./slices/work";
import { promoReducer } from "./slices/promo";
import calendarSlice from "./slices/calendarSlice";

const store = configureStore({
  reducer: {
    order: orderReducer,
    request: requestReducer,
    auth: authReducer,
    user: userReducer,
    role: roleReducer,
    work: workReducer,
    promo: promoReducer,
    calendar: calendarSlice,
  },
});

export default store;
