import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./MyCalendar.scss"; // Добавьте для стилизации модального окна

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Для хранения данных выбранного события
  const [isModalOpen, setIsModalOpen] = useState(false); // Управление модальным окном

  // Получаем все заказы из Redux
  const allOrders = useSelector(state => state.calendar.allOrders);

  useEffect(() => {
    // Преобразуем все заказы из Redux в формат событий для календаря
    if (allOrders && allOrders.length > 0) {
      const orderEvents = allOrders.map(order => {
        const date = new Date(order.date); // Преобразуем миллисекунды в дату
        const formattedTime = date.toLocaleTimeString("ru-RU", {
          hour: "2-digit",
          minute: "2-digit",
        }); // Форматируем время (часы и минуты)
        return {
          id: order.id, // Добавляем уникальный ID (если есть)
          title: `Уборка в ${formattedTime}`, // Заголовок с временем
          start: date,
          end: new Date(date.getTime() + 30 * 60 * 1000), // Длительность уборки 30 минут
          price: order.price, // Цена уборки
        };
      });
      setEvents(orderEvents);
    }
  }, [allOrders]);

  const handleSelectEvent = event => {
    setSelectedEvent(event); // Сохраняем данные выбранного события
    setIsModalOpen(true); // Открываем модальное окно
  };

  const closeModal = () => {
    setIsModalOpen(false); // Закрываем модальное окно
    setSelectedEvent(null); // Сбрасываем выбранное событие
  };

  return (
    <div style={{ height: "100vh", position: "relative" }}>
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectEvent={handleSelectEvent} // Обработчик клика на событие
        defaultView="month"
        views={["month", "week", "day"]}
        step={30}
        showMultiDayTimes
        defaultDate={new Date()}
      />

      {isModalOpen && selectedEvent && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>Детали уборки</h2>
            <p>
              <strong>Время:</strong> {selectedEvent.title}
            </p>
            <p>
              <strong>Цена:</strong> {selectedEvent.price} ₽
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyCalendar;
