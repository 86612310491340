import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Асинхронное получение всех заказов
export const fetchOrder = createAsyncThunk("order/fetchOrder", async () => {
  const { data } = await axios.get("/api/order");
  return data;
});

const initialState = {
  allOrders: [], // Все заказы
  orders: [], // Отфильтрованные заказы (по state !== false)
  filteredOrders: [],
  selectedDates: [null, null], // Диапазон выбранных дат
  status: "idle",
  error: null,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setSelectedDates: (state, action) => {
      state.selectedDates = action.payload;
      // Фильтруем заказы по выбранным датам и состоянию
      const [start, end] = action.payload;
      if (start && end) {
        state.filteredOrders = state.orders.filter(order => {
          const orderDate = new Date(order.date);
          return orderDate >= start && orderDate <= end;
        });
      } else {
        state.filteredOrders = [];
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchOrder.pending, state => {
        state.status = "loading";
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Сохраняем все заказы в allOrders
        state.allOrders = action.payload.map(({ date, price, state }) => ({
          date,
          price,
          state,
        }));

        // Сохраняем только отфильтрованные заказы (с `state !== false`) в orders
        state.orders = state.allOrders.filter(order => order.state !== false);

        // Сбрасываем фильтр при обновлении данных
        state.filteredOrders = [];
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setSelectedDates } = calendarSlice.actions;

export default calendarSlice.reducer;
