import { Outlet } from "react-router-dom";
import NavMenu from "../nav/NavMenu";
import Header from "../header/Header";
import { SideMenu } from "../../widgets/SideMenu/SideMenu";

const Layout = () => {
  return (
    <>
      <div className="admin__wrapper">
        {/* <NavMenu /> */}
        <SideMenu />
        <div className="layout__wrapper">
          <Header />
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
