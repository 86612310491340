import PaymentTable from "../../widgets/PaymentTable/PaymentTable";
import CustomCalendar from "../../entities/CustomCalendar/CustomCalendar";
import InfoProfitBlock from "../../entities/InfoProfitBlock/InfoProfitBlock";
import ProfitChart from "../../feature/ProfitChart/ProfitChart";

import { fetchOrder } from "../../redux/slices/order";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Finance = () => {
  const [isLoading, setIsLoading] = useState(true); // Изначально устанавливаем, что загрузка идет
  const [orderState, setOrderState] = useState(null);
  const [error, setError] = useState(null); // Добавлено состояние для ошибки

  const [filteredOrders, setFilteredOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().slice(0, 10)
  ); // Изначально текущая дата

  const dispatch = useDispatch();
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    setIsLoading(true); // Устанавливаем, что загрузка началась
    dispatch(fetchOrder())
      .then(() => {
        setIsLoading(false); // Загрузка завершена
      })
      .catch(error => {
        setIsLoading(false); // Если произошла ошибка, также устанавливаем, что загрузка завершена
        setError("Не удалось загрузить заказы. Попробуйте еще раз.");
      });
  }, [dispatch]);

  // Фильтрация и суммирование заказов по выбранной дате
  const filterAndSumOrdersByDate = (orders, targetDate) => {
    const targetDateString = new Date(targetDate).toLocaleDateString();

    const filteredOrders = orders.filter(order => {
      const orderDate = new Date(order.date).toLocaleDateString();
      return orderDate === targetDateString;
    });

    const totalSum = filteredOrders.reduce(
      (sum, order) => sum + order.price,
      0
    );

    setFilteredOrders(filteredOrders);
    setTotalPrice(totalSum);
  };

  useEffect(() => {
    if (order?.items) {
      filterAndSumOrdersByDate(order.items, selectedDate);
    }
  }, [order, selectedDate]);

  if (isLoading) return <div>Загрузка...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div
      style={{
        width: "90%",
        margin: "0 auto",
      }}>
      {/* <div>
        <h1>Заказы</h1>
        <label htmlFor="date">Выберите дату:</label>
        <input
          type="date"
          id="date"
          value={selectedDate}
          onChange={e => setSelectedDate(e.target.value)}
        />
        <table>
          <thead>
            <tr>
              <th>Дата</th>
              <th>Цена</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map(order => (
              <tr key={order._id}>
                <td>{new Date(order.date).toLocaleDateString()}</td>
                <td>{order.price} ₽</td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2>Общая сумма: {totalPrice} ₽</h2>
      </div> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          boxSizing: "border-box",
          padding: "0 42px",
        }}>
        <CustomCalendar />
        <InfoProfitBlock />
      </div>
      <ProfitChart />
      <PaymentTable />
    </div>
  );
};

export default Finance;
