import React from "react";
import "./UsersCard.scss";

const UsersCard = ({
  userName,
  phone,
  email,
  balance,
  address,
  role,
  bonusBalance,
}) => {
  return (
    <div className="users-card">
      <div className="users-card__info">
        <h2 className="users-card__name">{userName || "Имя не указано"}</h2>
        <p className="users-card__phone">{phone || "Телефон не указан"}</p>
        <p className="users-card__email">{email}</p>
        {/* <p className="users-card__email">{role}</p> */}
        {/* <p className="users-card__email">{bonusBalance}</p> */}
      </div>
      <div className="users-card__address">
        <h3 className="users-card__address-title">Адрес</h3>
        {address && address.length > 0 ? (
          <>
            <p>{`город - ${address[0]}`}</p>
            <p>{`улица - ${address[1]}`}</p>
            <p>{`Квартира - ${address[2]}`}</p>
          </>
        ) : (
          <p className="users-card__no-address">Адрес не добавлен</p>
        )}
      </div>

      <div className="users-card__actions">
        <div className="users-card__balance">
          <div className="users-card__balance-title">Баланс</div>
          <p className="users-card__balance-value">{balance} руб.</p>
        </div>
        <div className="users-card__buttons">
          <button className="users-card__button users-card__button--add">
            добавить Баллы
          </button>
          <button className="users-card__button users-card__button--delete">
            удалить аккаунт
          </button>
        </div>
      </div>
    </div>
  );
};

export default UsersCard;
