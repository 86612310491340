import React, { useEffect } from "react";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";

import "./CustomCalendar.scss";
import { fetchOrder, setSelectedDates } from "../../redux/slices/calendarSlice";

const CustomCalendar = () => {
  const dispatch = useDispatch();
  const selectedDates = useSelector(state => state.calendar.selectedDates);

  useEffect(() => {
    dispatch(fetchOrder());
  }, [dispatch]);

  const handleDateChange = value => {
    dispatch(setSelectedDates(value));
    console.log(value);
  };

  return (
    <div className="calendar__wrapper">
      <Calendar
        onChange={handleDateChange}
        value={selectedDates}
        selectRange
        locale="ru-RU"
        nextLabel=">"
        prevLabel="<"
        className="calendar__services"
      />
    </div>
  );
};

export default CustomCalendar;
